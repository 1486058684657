<template>
  <div v-if="!category.ParentCategoryID" class="categories">
    <router-link
      :to="`/${$i18n.locale}/shop/${category.CategoryID}`"
      :class="{
        'text-primary': currentCategory == category.CategoryID,
        'main-category': true
      }"
      >{{ category.Description }}
    </router-link>
    <ul
      v-for="subcategory in currentSubCategories"
      :key="subcategory.CategoryID"
      class="subcategories"
    >
      <li>
        <router-link
          :to="`/${$i18n.locale}/shop/${subcategory.CategoryID}`"
          :class="{
            'text-primary': currentCategory == subcategory.CategoryID
          }"
          >{{ subcategory.Description }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    category: {
      type: Object,
      required: true,
      default: function() {
        return {}
      }
    },
    categories: {
      type: Array,
      required: true,
      default: function() {
        return []
      }
    }
  },
  computed: {
    currentCategory: function() {
      if (this.$route.params.categoryID) return this.$route.params.categoryID
      return ''
    },
    ...mapGetters({
      subCategories: 'categories/getSubCategories'
    }),
    currentSubCategories: function() {
      const subCategories = this.subCategories.filter(
        subCategory => subCategory.ParentCategoryID === this.category.CategoryID
      )
      // if any subcategory has Ranking not equal to null sort by ranking
      if (subCategories.some(subCategory => subCategory.Ranking !== null)) {
        return subCategories.sort((a, b) => {
          if (a.Ranking === null) return 1
          if (b.Ranking === null) return -1
          return a.Ranking - b.Ranking
        })
      }

      // sort subcategories by Description
      return subCategories.sort((a, b) => {
        if (a.Description < b.Description) return -1
        if (a.Description > b.Description) return 1
        return 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-category {
  color: #636363;
}

.categories {
  border-bottom: 2px solid #f0f0f0;
  padding: 20px;
  a {
    display: block;

    color: #333333 !important;

    line-height: 18px;
    padding-right: 55px;
    font-size: 15px;
  }
  a.text-primary {
    color: #25167a !important;
  }

  a:hover {
    color: #25167a !important;
    transition: color 0.15s;
  }

  li {
    padding: 6px 0;
    margin: 0px;
    position: relative;
  }

  .subcategories {
    list-style: none;
    padding-left: 0px;
    a {
      font-weight: normal;
      border: none;
      line-height: 18px;
      transition: color 0.15s;
    }
    a:hover {
      color: #25167a !important;
      transition: color 0.15s;
    }
    li {
      border: none;
    }
  }
}
</style>

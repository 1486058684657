const { getCategory } = require('./CategoryService')
import i18n from '@/locales/index'
import store from '@/state/store'

async function generateBreadCrumbs({ categoryID, item = false } = {}) {
  let breadCrumbs = []
  breadCrumbs.push({
    page: 'index',
    title: i18n.tc('breadcrumbs-title'),
    path: '/'
  })

  if (categoryID) {
    const categoryBreadCrumbs = await categoryToBreadCrumbs({
      categoryID,
      item
    })
    breadCrumbs = breadCrumbs.concat(categoryBreadCrumbs)
  }

  return breadCrumbs
}

async function categoryToBreadCrumbs({ categoryID, item = false } = {}) {
  let breadCrumbs = []

  // parse categoryID as number
  categoryID = parseInt(categoryID)

  const categoryIDIsNum = /^\d+$/.test(categoryID)

  const categories = store.getters['categories/getCategories']

  const currentCategoryFromStore = categories?.find(
    category => category.CategoryID === categoryID
  )

  const currentCategory =
    currentCategoryFromStore ??
    (await getCategory({
      categoryID,
      slug: !categoryIDIsNum
    }))

  if (currentCategory.ParentCategoryID) {
    const parentCategoryFromStore = categories.find(
      category => category.CategoryID === currentCategory.ParentCategoryID
    )

    const parentCategory =
      parentCategoryFromStore ??
      (await getCategory({
        categoryID: currentCategory.ParentCategoryID,
        slug: false
      }))
    if (item) {
      await breadCrumbs.push({
        title: capitalizeFirstLetter(currentCategory.Description),
        path: `/shop/${parentCategory.Slug ??
          parentCategory.CategoryID}/${currentCategory.CategoryID ??
          parentCategory.CategoryID}`
      })
    }

    await breadCrumbs.push({
      title: parentCategory.Description,
      path: `/shop/${parentCategory.Slug ?? parentCategory.CategoryID}`
    })
  } else {
    await breadCrumbs.push({
      page: 'categories',
      title: capitalizeFirstLetter(currentCategory.Description),
      params: {
        categoryID: currentCategory.CategoryID,
        lang: i18n.locale
      },
      path: `/shop/${currentCategory.Slug ?? currentCategory.CategoryID}`
    })
  }

  return breadCrumbs.reverse()
}

function capitalizeFirstLetter(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1)
  return null
}

module.exports = { generateBreadCrumbs }

<template>
  <div class="container">
    <b-alert v-if="notificationMessage" show variant="info">{{
      notificationMessage
    }}</b-alert>

    <Items v-if="$store.state.user.Email" :search-term="searchTerm" />
  </div>
</template>

<script>
// @ is an alias to /src
import Items from '@/components/items/Index.vue'

export default {
  name: 'Shop',
  components: {
    Items
  },
  metaInfo() {
    return {
      title: `${this.$t('home')}`
    }
  },
  computed: {
    searchTerm: function() {
      if (this.$route.params.searchTerm) {
        return this.$route.params.searchTerm
      } else {
        return null
      }
    },
    notificationMessage: function() {
      if (this.$route.query.loggedOut) return this.$t('message.logoutSuccess')
      if (this.$route.query.loggedIn) return this.$t('message.loginSuccess')
      if (this.$route.query.sessionExpired)
        return this.$t('logged-out-session-expired')
      return null
    }
  },
  created() {
    if (this.$store.getters.account.RoleName === 'student' || this.$store.getters.account.RoleName === 'employee') {
      this.$router.push({ name: 'account-user' })
    }
    this.$store.dispatch('forwardToLoginWhenAnonymous')
  }
}
</script>

<template>
  <form @submit.prevent="search" class=" w-100 mb-3">
    <b-input-group class="mt-0">
      <b-form-input
        v-model="searchTerm"
        :state="searchValidation"
        :placeholder="$t('general.searchFor')"
      ></b-form-input>
      <b-input-group-append>
        <b-button variant="primary" class="" type="submit">{{
          $t('general.search')
        }}</b-button>
      </b-input-group-append>
    </b-input-group>
  </form>
</template>

<script>
export default {
  name: 'SearchBar',
  data: function() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    searchValidation: function() {
      if (
        this.searchTerm.length > 1 ||
        this.searchTerm.length == 0 ||
        this.searchTerm == ''
      ) {
        return null
      } else {
        return false
      }
    }
  },
  methods: {
    search: async function(event) {
      event.preventDefault()

      await this.$router.push({
        name: 'search',
        params: { searchTerm: this.searchTerm }
      })
      this.searchTerm = ''
    }
  }
}
</script>

<style scoped></style>
